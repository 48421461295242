import ReactModal from 'react-modal';

const RemoveModal = ({ open, closeHandle, confirm, content }) => {
    return (
        <ReactModal isOpen={open} onRequestClose={closeHandle}>
            <div className="modal">
                <h3>Opravdu si přejete smazat {content}?</h3>
                <div className="buttons">
                    <button className='btn btn-primary' onClick={closeHandle}>Zrušit</button>
                    <button className='btn btn-danger' onClick={confirm}>Smazat</button>
                </div>
            </div>
        </ReactModal>
    )
}

export default RemoveModal