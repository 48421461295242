import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
    return (
        <div className="errorpage">
            <h3>Error 404</h3>
            <p>Stránka nenalezena. <Link to='/'>Zpět domů.</Link></p>
        </div>
    )
}

export default ErrorPage