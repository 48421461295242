import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { IconArrowBackUp } from '@tabler/icons-react'

const ContactEditor = () => {
    const { textId } = useParams()
    const [text, setText] = useState()
    const [contacts, setContacts] = useState()
    const [update, setUpdate] = useState(true)

    const getText = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'texts',
                    type: 'id',
                    specific: textId
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setText(response.data[0])
            setContacts(JSON.parse(response.data[0].text))
        } catch (error) {
            console.log(error);
            toast.error('Nepodařilo se stáhnout obsah')
        }
    }

    useEffect(() => {
        getText()
    }, [])

    const changeText = (newText, type) => {
        switch (type) {
            case 'header':
                text.header = newText
                break
            case 'phone':
                contacts.phone = newText
                break
            case 'mail':
                contacts.mail = newText
                break
            case 'street':
                contacts.address.street = newText
                break
            case 'city':
                contacts.address.city = newText
                break
            case 'postcode':
                contacts.address.postcode = newText
                break
            case 'fb':
                contacts.socials.facebook = newText
                break
            case 'ig':
                contacts.socials.instagram = newText
                break
            default:
                break
        }
        setUpdate(!update)
    }

    const saveText = async (e) => {
        e.preventDefault()
        toast.info('Ukládání')
        text.text = JSON.stringify(contacts)
        const uploadData = JSON.stringify(text)
        try {
            await axios.patch(process.env.REACT_APP_API_URL + '/site', {
                data: uploadData,
                specific: {
                    type: 'id',
                    specific: textId
                }
            },
            {
                params: {
                    database: 'suchomel',
                    table: 'texts'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            toast.success('Text uložen')
        } catch (error) {
            console.log(error);
            toast.error('Ukládání se nezdařilo')
        }
    }

    return (
        <div className="wrapper">
            <h2 className="wrapper__heading">
                <Link to='/texts'><IconArrowBackUp /></Link>
                Editace kontaktů
            </h2>
            <form onSubmit={(e) => {saveText(e)}} className="wrapper__form">
                <div className="form-group">
                    <p>Nadpis</p>
                    <input type="text" value={text?.header} required onChange={(e) => {changeText(e.target.value, 'header')}} />
                </div>
                <div className="form-group">
                    <p>Telefon</p>
                    <input type="text" value={contacts?.phone} required onChange={(e) => {changeText(e.target.value, 'phone')}} />
                </div>
                <div className="form-group">
                    <p>Email</p>
                    <input type="text" value={contacts?.mail} required onChange={(e) => {changeText(e.target.value, 'mail')}} />
                </div>
                <div className="form-group">
                    <p>Adresa</p>
                    <p className='smaller'>Ulice</p>
                    <input type="text" value={contacts?.address.street} required onChange={(e) => {changeText(e.target.value, 'street')}} />
                    <p className='smaller'>Město</p>
                    <input type="text" value={contacts?.address.city} required onChange={(e) => {changeText(e.target.value, 'city')}} />
                    <p className='smaller'>PSČ</p>
                    <input type="text" value={contacts?.address.postcode} required onChange={(e) => {changeText(e.target.value, 'postcode')}} />
                </div>
                <div className="form-group">
                    <p>Sociální sítě</p>
                    <p className='smaller'>Facebook</p>
                    <input type="text" value={contacts?.socials.facebook} onChange={(e) => {changeText(e.target.value, 'fb')}} />
                    <p className='smaller'>Instagram</p>
                    <input type="text" value={contacts?.socials.instagram} onChange={(e) => {changeText(e.target.value, 'ig')}} />
                </div>
                <button className='btn btn-submit'>Uložit změny</button>
            </form>
        </div>
    )
}

export default ContactEditor