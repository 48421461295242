import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ButtonLoader } from '../Loaders';
import styled from 'styled-components';
import RemoveModal from '../Modals/RemoveModal';
import ImageEditModal from '../Modals/ImageEditModal';
import { GalleryEditorImage } from '../';
import { IconArrowBackUp } from '@tabler/icons-react';

const GalleryEditor = () => {
    const [galleryInfo, setGalleryInfo] = useState()
    const [images, setImages] = useState()
    const [categories, setCategories] = useState()
    const [files, setFiles] = useState()
    const [fileCategory, setFileCategory] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [uploadSuccess, setUploadSuccess] = useState(0);
    const [uploadCount, setUploadCount] = useState(0);
    const { galleryId } = useParams()
    const [ modalOpen, setModalOpen] = useState(false)
    const [ editModalOpen, setEditModalOpen] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const [editId, setEditId] = useState(null)
    const [editingImage, setEditingImage] = useState(false)


    const Bar = styled.div`
        width: 200px;
        height: 3px;
        background-color: #ffffff24;
        position: relative;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            content: '';
            transition: .5s ease;
            width: ${uploadCount > 0 ? ((uploadSuccess / uploadCount) * 100).toString() : '0'}%;
        }
    `

    const closeHandle = () => {
        setModalOpen(false)
        setEditModalOpen(false)
        setDeleteId(null)
    }

    const getGallery = async () => {
        try {
            const infoResp = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'gallerySets',
                    type: 'id',
                    specific: galleryId
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            const imagesResp = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'gallery',
                    type: 'gallerySet',
                    specific: galleryId
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            const catResp = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'categories',
                    type: 'categorySet',
                    specific: infoResp.data[0].categorySet
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setGalleryInfo(infoResp.data[0])
            setImages(imagesResp.data)
            setCategories(catResp.data)
        } catch (error) {
            console.log(error)
            toast.error('Nepodařilo se stáhnout data')
        }
    }

    const getImages = async () => {
        try {
            const imagesResp = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'gallery',
                    type: 'gallerySet',
                    specific: galleryId
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setImages(imagesResp.data)
        } catch (error) {
            console.log(error)
            toast.error('Nepodařilo se stáhnout data')
        }
    }

    useEffect(() => {
        getGallery()
    }, [])

    const addImage = async (file) => {
        if (!file.type === 'image/jpeg' || !file.type === 'image/jpg' || !file.type === 'image/png') {
            toast.error('Soubor není obrázek')
            return false
        } else if (file.size > 10000000) {
            toast.error('Fotka je moc velká, maximální velikost je 10MB')
            return false
        }
        setUploading(true)
        const formData = new FormData()
        formData.append('file', file)
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/site/imageupload',
                formData,
                {
                    params: {
                        database: 'suchomel',
                        table: 'gallery',
                        folder: 'suchomel',
                        category: fileCategory
                    },
                    headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                        'content-type': 'multipart/form-data'
                    }
                }
            )
            setUploading(false)
            if (response.data.status) {
                getImages()
                return true
            }
            return false
        } catch (error) {
            console.log(error);
        }
    }

    const uploadImages = async (e) => {
        e.preventDefault()
        if (!files || fileCategory < 1) {
            return toast.error('Není nahrán soubor nebo není zvolena kategorie')
        }
        setUploading(true)
        setUploadCount(files.length)
        for (let i = 0; i < files.length; i++) {
            const upload = await addImage(files[i])
            if (upload) {
                setUploadSuccess(i + 1)
            }
        }
        setUploading(false)
        setUploadCount(0)
        setUploadSuccess(0)
        toast.success(`Fotografie úspěšně nahrány`)
    }

    const findCategory = (categoryId) => {
        return categories.find(category => { return categoryId === category.id })
    }

    const handleCategoryChange = async (imageId, categoryId) => {
        const data = JSON.stringify({
            category: categoryId
        })
        try {
            await axios.patch(process.env.REACT_APP_API_URL + '/site', {
                data: data,
                specific: {
                    type: 'id',
                    specific: imageId
                }
            },
            {
                params: {
                    database: 'suchomel',
                    table: 'gallery'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            toast.success(`Kategorie změněna na: ${findCategory(categoryId).name}`)
        } catch (error) {
            console.log(error)
            toast.error('Změna kategorie se nepovedla')
        }
    }

    const removeImage = async () => {
        setModalOpen(false)
        try {
            await axios.delete(process.env.REACT_APP_API_URL + '/site/imageremove', {
                data: {
                    type: 'id',
                    specific: deleteId
                },
                params: {
                    database: 'suchomel',
                    table: 'gallery',
                    folder: 'suchomel'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            getImages()
            setDeleteId(null)
            toast.success('Fotka byla úspěšně odstraněna')
        } catch (error) {
            console.log(error)
            toast.error('Nepovedlo se odstranit fotku')
        }
    }

    const handleDelete = (imageId) => {
        setModalOpen(true)
        setDeleteId(imageId)
    }

    const editImage = async (rotate) => {
        setEditingImage(true)
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/site/editimage',
                {},
                {
                    params: {
                        database: 'suchomel',
                        table: 'gallery',
                        folder: 'suchomel',
                        filename: getImageUrl().imgUrl,
                        rotate: rotate
                    },
                    headers: {
                        Authorization: process.env.REACT_APP_API_KEY
                    }
                }
            )
            setEditingImage(false)
            if (response.data.status) {
                setEditModalOpen(false)
                toast.success('Obrázek upraven')
                return true
            }
            toast.error('Nastala chyba při úpravě')
            return false
        } catch (error) {
            console.log(error);
        }
    }

    const handleImageEdit = (imageId) => {
        setEditId(imageId)
        setEditModalOpen(true)
    }

    const getImageUrl = () => {
        return editId ? images.find(image => image.id === editId) : ''
    }

    return (
        <div className="wrapper">
            <h2 className="wrapper__heading">
                <Link to='/gallery'><IconArrowBackUp /></Link>
                Editace galerie: {galleryInfo?.name}
            </h2>
            <div className="wrapper__gallery">
                <div className="row-content">
                    <form onSubmit={(e) => {uploadImages(e)}} className='row'>
                        <div className="form-group">
                            <input type="file" file={files} multiple onChange={(e) => {setFiles(e.target.files)}} required />
                        </div>
                        <div className="form-group">
                            <select onChange={(e) => {setFileCategory(parseInt(e.target.value))}} required >
                                <option value="0" hidden>Vyberte kategorii</option>
                                {categories?.map(category => {
                                    return <option value={category.id} key={category.id}>{category.name}</option>
                                })}
                            </select>
                        </div>
                        {uploading ?
                            <ButtonLoader classnames={'btn btn-submit'} />:
                            <button className='btn btn-submit'>Přidat</button>
                        }
                    </form>
                    {uploading &&
                        <div className="uploadInfo">
                            <p>Nahrávání: {uploadSuccess}/{uploadCount}</p>
                            <Bar className='bar' />
                        </div>
                    }
                </div>
                <div className="gallery">
                    {images?.map(image => {
                        return (
                            <GalleryEditorImage
                                image={image}
                                categories={categories}
                                handleCategoryChange={handleCategoryChange}
                                handleDelete={handleDelete}
                                handleImageEdit={handleImageEdit}
                            />
                        )
                    })}
                </div>
            </div>
            <RemoveModal open={modalOpen} closeHandle={closeHandle} confirm={removeImage} content={'tento obrázek'} />
            <ImageEditModal open={editModalOpen} closeHandle={closeHandle} confirm={editImage} imageUrl={getImageUrl} editing={editingImage} />
        </div>
    )
}

export default GalleryEditor