import { IconTrash } from '@tabler/icons-react'

const GalleryEditorImage = ({ image, categories, handleCategoryChange, handleDelete, handleImageEdit }) => {
    return (
        <div className="image-wrap" key={image.id}>
            <img
                onClick={() => { handleImageEdit(image.id) }}
                src={process.env.REACT_APP_API_URL + '/resource/suchomel/' + image.imgUrl + '?' + new Date().valueOf()}
                alt={image.imgUrl}
                key={image.id}
            />
            <div className="image-options">
                <select defaultValue={image.category} onChange={(e) => { handleCategoryChange(image.id, parseInt(e.target.value)) }}>
                    {categories.map(category => {
                        return <option value={category.id} key={category.id}>{category.name}</option>
                    })}
                </select>
                <button className="btn btn-danger" onClick={() => { handleDelete(image.id) }}>
                    <IconTrash />
                </button>
            </div>
        </div>
    )
}

export default GalleryEditorImage