import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { IconArrowBackUp } from '@tabler/icons-react'

const TextEditor = () => {
    const { textId } = useParams()
    const [text, setText] = useState()
    const [update, setUpdate] = useState(true)

    const getText = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'texts',
                    type: 'id',
                    specific: textId
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setText(response.data[0])
        } catch (error) {
            console.log(error);
            toast.error('Nepodařilo se stáhnout obsah')
        }
    }

    useEffect(() => {
        getText()
    }, [])

    const changeText = (newText, type) => {
        switch (type) {
            case 'header':
                text.header = newText
                break
            case 'text':
                text.text = newText
                break
            default:
                break
        }
        setUpdate(!update)
    }

    const saveText = async (e) => {
        e.preventDefault()
        toast.info('Ukládání')
        const uploadData = JSON.stringify(text)
        try {
            await axios.patch(process.env.REACT_APP_API_URL + '/site', {
                data: uploadData,
                specific: {
                    type: 'id',
                    specific: textId
                }
            },
            {
                params: {
                    database: 'suchomel',
                    table: 'texts'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            toast.success('Text uložen')
        } catch (error) {
            console.log(error);
            toast.error('Ukládání se nezdařilo')
        }
    }

    return (
        <div className="wrapper">
            <h2 className="wrapper__heading">
                <Link to='/texts'><IconArrowBackUp /></Link>
                Editace textu
            </h2>
            <form onSubmit={(e) => {saveText(e)}} className="wrapper__form">
                <div className="form-group">
                    <p>Nadpis</p>
                    <input type="text" value={text?.header} required onChange={(e) => {changeText(e.target.value, 'header')}} />
                </div>
                <div className="form-group">
                    <p>Text</p>
                    <textarea value={text?.text} onChange={(e) => {changeText(e.target.value, 'text')}} ></textarea>
                </div>
                <button className='btn btn-submit'>Uložit změny</button>
            </form>
        </div>
    )
}

export default TextEditor