import { Main, Login } from './components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jsCookies from 'js-cookies';
import { useEffect, useState } from 'react';

const App = () => {
  const user = jsCookies.getItem('userToken');
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    const themeTmp = localStorage.getItem('theme');
    if (themeTmp) setTheme(themeTmp);
    else localStorage.setItem('theme', 'dark');
    if (themeTmp === 'light') {
        document.querySelector('body').classList.add('light-theme');
    }
  }, [])

  return (
    <>
      {
        user ?
        <Main />:
        <Login />
      }
      <ToastContainer autoClose={3000} limit={5} />
    </>
  )
}

export default App;
