import { IconLoader2 } from '@tabler/icons-react'

const ButtonLoader = ({ classnames }) => {
    return (
        <button className={classnames + ' btn-loading'} disabled={true}>
            <IconLoader2 className='icon' size={16} />
        </button>
    )
}

export default ButtonLoader