import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import jsCookies from 'js-cookies';
import { IconLogout, IconPolygon } from '@tabler/icons-react';

const Menu = ({ pages, user }) => {
    const [openedMenu, setOpenedMenu] = useState(false)
    const [mobileMenu, setMobileMenu] = useState(false)
    const currentPage = window.location.pathname
    const token = jsCookies.getItem('userToken')

    const checkUrl = async (e) => {
        document.querySelector('.active')?.classList.remove('active')
        e.classList.add('active')
    }

    const logout = async () => {
        try {
            await axios.delete(process.env.REACT_APP_API_URL + '/site/logout', {
                data: {
                    data: {
                        type: 'token',
                        specific: token
                    }
                },
                params: {
                    database: 'admin_panel',
                    table: 'tokens'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            jsCookies.removeItem('userToken')
            window.location.href = '/'
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (window.innerWidth < 769) {
            setMobileMenu(true)
        }
    }, [])


    if (!mobileMenu) {
        return (
            <nav className='nav'>
                <Link to='/' className='heading-link'>
                    <h2 className='heading'>
                        <IconPolygon className='icon' />
                        Mamooth
                    </h2>
                </Link>
                <ul className='nav__menu'>
                    {pages.filter(page => page.menu).map(page => {
                        return (
                            <li className='nav__link' key={page.path}>
                                <Link to={page.path} className={currentPage === page.path ? 'active' : ''} onClick={(e) => {checkUrl(e.target)}}>
                                    {page.icon}
                                    {page.text}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                <div className={currentPage === '/user' ? 'user active' : 'user'}>
                    <div className="user__avatar">
                        <img src={'https://api.dcreative.cz/resource/users/' + user?.img} alt="User avatar" />
                    </div>
                    <div className="user__info">
                        <h3 className='name'>{user?.name}</h3>
                    </div>
                    <button className='btn logout' onClick={logout}>
                        <IconLogout />
                    </button>
                    <Link to='/user' onClick={(e) => {checkUrl(e.target)}} className='link-button'>
                    </Link>
                </div>
            </nav>
        )
    } else {
        return (
            <>
                <button onClick={() => {setOpenedMenu(!openedMenu)}} className={openedMenu ? 'menu-btn open' : 'menu-btn'}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </button>
                <nav onClick={() => {setOpenedMenu(!openedMenu)}} className={openedMenu ? 'nav open' : 'nav'}>
                    <Link to='/' className='heading-link'>
                        <h2 className='heading'>
                            <IconPolygon className='icon' />
                            Mamooth
                        </h2>
                    </Link>
                    <ul className='nav__menu'>
                        {pages.filter(page => page.menu).map(page => {
                            return (
                                <li className='nav__link' key={page.path}>
                                    <Link to={page.path} className={currentPage === page.path ? 'active' : ''} onClick={(e) => {checkUrl(e.target)}}>
                                        {page.icon}
                                        {page.text}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                    <div className={currentPage === '/user' ? 'user active' : 'user'}>
                        <div className="user__avatar">
                            <img src={'https://api.dcreative.cz/resource/users/' + user?.img} alt="User avatar" />
                        </div>
                        <div className="user__info">
                            <h3 className='name'>{user?.name}</h3>
                        </div>
                        <button className='btn logout' onClick={logout}>
                            <IconLogout />
                        </button>
                        <Link to='/user' onClick={(e) => {checkUrl(e.target)}} className='link-button'>
                        </Link>
                    </div>
                </nav>
            </>
        )
    }
}

export default Menu