import { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { ButtonLoader } from '../Loaders';
import { IconRotate, IconRotateClockwise } from '@tabler/icons-react';

const ImageEditModal = ({ open, closeHandle, confirm, imageUrl, rotation, editing }) => {
    const [rotate, setRotate] = useState(rotation)

    const Image = styled.img`
        transform-origin: center;
        transform: rotate(${rotate}deg);
    `

    useEffect(() => {
        setRotate(0)
    }, [open])

    return (
        <ReactModal isOpen={open} onRequestClose={closeHandle}>
            <div className="modal edit-modal">
                <h3>Úprava fotografie</h3>
                <div className="image-container">
                    <Image src={process.env.REACT_APP_API_URL + '/resource/suchomel/' + imageUrl().imgUrl + '?' + editing} alt='Obrázek k úpravě' />
                </div>
                <div className="buttons options">
                    <button className='btn btn-primary' onClick={() => {setRotate(rotate + 90)}}>
                        <IconRotateClockwise />
                    </button>
                    <button className='btn btn-primary' onClick={() => {setRotate(rotate - 90)}}>
                        <IconRotate />
                    </button>
                </div>
                <div className="buttons">
                    <button className='btn btn-primary' onClick={closeHandle}>Zrušit</button>
                    {!editing ?
                        <button className='btn btn-submit' onClick={() => {confirm(rotate)}}>Uložit změny</button>:
                        <ButtonLoader classnames={'btn btn-submit'} />
                    }
                </div>
            </div>
        </ReactModal>
    )
}

export default ImageEditModal