import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import jsCookies from 'js-cookies';
import { ButtonLoader } from '../Loaders';
import { IconPolygon } from '@tabler/icons-react';

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [logging, setLogging] = useState(false)

    const login = async (e) => {
        e.preventDefault()
        setLogging(true)
        const data = {
            email: email,
            password: password,
            rights: 'suchomel'
        }
        try {
            const ip = await axios.get('https://api.ipify.org?format=json')
            data.ipAddress = ip.data.ip
            const response = await axios.post(process.env.REACT_APP_API_URL + '/site/login', {
                data: JSON.stringify(data)
            },
            {
                params: {
                    database: 'suchomel',
                    table: 'texts'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setLogging(false)
            if (response.data.logged) {
                toast.success('Příhlášeno, probíhá přesměrování')
                jsCookies.setItem('userToken', response.data.token, { expires: 7 })
                window.location.reload()
            } else if (response.data.rights) {
                return toast.error('K této stránce nemáš přístup')
            } else {
                return toast.error('Špatné přihlašovácí údaje')
            }
        } catch (error) {
            console.log(error)
            toast.error('Přihlášení se nezdařilo')
        }
    }

    return (
        <div className='login'>
            <div className="content">
                <h2 className='headline'><IconPolygon className='icon' />Mamooth</h2>
                <p className='description'>Víteje v administraci Vaší stránky, pro pokračování se přihlašte Vaším emailem a heslem</p>
                <form onSubmit={(e) => {login(e)}} className="form">
                    <div className="form-group">
                        <p>Email</p>
                        <input type="email" value={email} onChange={(e) => {setEmail(e.target.value)}} required placeholder='Email' />
                    </div>
                    <div className="form-group">
                        <p>Heslo</p>
                        <input type="password" value={password} onChange={(e) => {setPassword(e.target.value)}} required placeholder='Heslo' />
                    </div>
                    {logging ?
                        <ButtonLoader classnames={"btn btn-primary"} />:
                        <button className='btn btn-primary'>Přihlásit</button>
                    }
                </form>
            </div>
        </div>
    )
}

export default Login