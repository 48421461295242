import { useState, useEffect } from 'react';
import jsCookies from 'js-cookies';
import axios from 'axios';
import { toast } from 'react-toastify';

const User = () => {
    const [user, setUser] = useState({})
    const [passwords, setPasswords] = useState({current: '', newPass: '', newPassRe: ''})
    const [update, setUpdate] = useState(true)
    const [theme, setTheme] = useState('dark');

    const getUser = async () => {
        const token = jsCookies.getItem('userToken')
        const ip = await axios.get('https://api.ipify.org?format=json')
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site/user', {
                params: {
                    database: 'admin_panel',
                    table: 'tokens',
                    token: token,
                    ipAddress: ip.data.ip
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            if (!response.data.user) {
                jsCookies.removeItem('userToken')
                window.location.href = '/'
            }
            setUser(response.data.user)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const themeTmp = localStorage.getItem('theme');
        if (themeTmp) setTheme(themeTmp);
        else localStorage.setItem('theme', 'dark');
        if (themeTmp === 'light') {
            document.querySelector('body').classList.add('light-theme');
        }
        getUser()
    }, [])

    const changeTheme = (themeTmp) => {
        if (themeTmp === theme) return;
        setTheme(themeTmp);
        localStorage.setItem('theme', themeTmp);
        if (themeTmp === 'light') {
            document.querySelector('body').classList.add('light-theme');
        } else {
            document.querySelector('body').classList.remove('light-theme');
        }
    }

    const handlePassChange = (type, text) => {
        switch (type) {
            case 'current':
                passwords.current = text
                break;
            case 'newPass':
                passwords.newPass = text
                break;
            case 'newPassRe':
                passwords.newPassRe = text
                break;
            default:
                break;
        }
        setPasswords(passwords)
        setUpdate(!update)
    }

    const setPassword = async (e) => {
        e.preventDefault()
        if (passwords.newPass !== passwords.newPassRe) {
            return toast.error('Nová hesla se neshodují')
        } else if (passwords.newPass === passwords.current) {
            return toast.error('Staré a nové heslo se shoduje')
        }
        const data = JSON.stringify(passwords)
        try {
            const response = await axios.patch(process.env.REACT_APP_API_URL + '/site/changepassword', {
                data: data,
                specific: {
                    type: 'id',
                    specific: user.id
                }
            },
            {
                params: {
                    database: 'admin_panel',
                    table: 'users'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            if (!response.data.changed) {
                return toast.error('Staré heslo se neshoduje')
            }
            setPasswords({current: '', newPass: '', newPassRe: ''})
            toast.success('Heslo změněno')
        } catch (error) {
            console.log(error)
            toast.error('Nepodařilo se změnit hesla')
        }
    }

    const newPassClass = () => {
        if (passwords.newPass === '') {
            return ''
        } else if (8 > passwords.newPass.length) {
            return 'red-border'
        }
        return 'green-border'
    }

    const newPassReClass = () => {
        if (passwords.newPassRe === '') {
            return ''
        } else if (passwords.newPass !== passwords.newPassRe) {
            return 'red-border'
        }
        return 'green-border'
    }

    return (
        <div className="wrapper">
            <h2 className="wrapper__heading">{user?.name}</h2>
            {user &&
                <div className="wrapper__user">
                    <h3>Základní informace</h3>
                    <p>Celé jméno: <b>{user?.name}</b></p>
                    <p>Email: <b>{user?.email}</b></p>
                    <form onSubmit={(e) => {setPassword(e)}} autoComplete='off'>
                        <h3>Změna hesla</h3>
                        <div className="form-group">
                            <p>Momentální heslo</p>
                            <input
                                type="password"
                                value={passwords.current}
                                onChange={(e) => {handlePassChange('current', e.target.value)}}
                                required
                                placeholder='Momentální heslo'
                                autoComplete='off' />
                        </div>
                        <div className="form-group">
                            <p>Nové heslo</p>
                            <input
                                type="password"
                                className={newPassClass()}
                                value={passwords.newPass}
                                onChange={(e) => {handlePassChange('newPass', e.target.value)}}
                                required
                                placeholder='Nové heslo' />
                        </div>
                        <div className="form-group">
                            <p>Znovu nové heslo</p>
                            <input
                                type="password"
                                className={newPassReClass()}
                                value={passwords.newPassRe}
                                onChange={(e) => {handlePassChange('newPassRe', e.target.value)}}
                                required
                                placeholder='Znovu nové heslo' />
                        </div>
                        <button className="btn btn-submit">Změnit heslo</button>
                    </form>
                    <div className="theme-change">
                        <h3>Barevné téma</h3>
                        <div className="switch">
                            <button
                                className={theme === 'dark' ? "btn btn-change active" : "btn btn-change"}
                                onClick={() => {changeTheme('dark')}}>
                                Tmavé
                            </button>
                            <button
                                className={theme === 'light' ? "btn btn-change active" : "btn btn-change"}
                                onClick={() => {changeTheme('light')}}>
                                Světlé
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default User