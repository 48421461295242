import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios'
import SkeletonLoader from './Loaders/SkeletonLoader'

const Texts = () => {
    const [texts, setTexts] = useState()

    const getTexts = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'texts'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setTexts(response.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getTexts()
    }, [])

    return (
        <div className="wrapper">
            <h2 className='wrapper__heading'>Texty na webu</h2>
            <div className="wrapper__list">
                <ul>
                    {!texts &&
                        [...Array(3)].map((e, i) => {
                            return <SkeletonLoader key={i} />
                        })
                    }
                    {texts?.map(text => {
                        return <li key={text.id}><Link to={text.editArea === 'texts' ? '/texteditor/' + text.id : '/contacteditor/' + text.id}>{text.header}</Link></li>
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Texts