import { IconLoader2 } from '@tabler/icons-react'

const PageLoader = () => {
    return (
        <div className='loading'>
            <IconLoader2 className='icon' />
        </div>
    )
}

export default PageLoader