import React from 'react'

const NetworkError = () => {
    return (
        <div className="errorpage">
            <h3>Nastala chyba při stahování dat</h3>
            <p>Při stahování dat nastala chyba. Pravděpodobně je problém s Vaším připojením k internetu.</p>
        </div>
    )
}

export default NetworkError