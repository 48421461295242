import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import SkeletonLoader from '../Loaders/SkeletonLoader';
import RemoveModal from '../Modals/RemoveModal';
import { IconArrowBackUp, IconTrash } from '@tabler/icons-react';

const CategoryEditor = () => {
    const [categories, setCategories] = useState()
    const [categoryInfo, setCategoryInfo] = useState()
    const { categoryId } = useParams()
    const [categoryName, setCategoryName] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [deleteId, setDeleteId] = useState(null)

    const getCategory = async () => {
        try {
            const infoResp = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'categorySets',
                    type: 'id',
                    specific: categoryId
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            const catResp = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'categories',
                    type: 'categorySet',
                    specific: categoryId
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setCategories(catResp.data)
            setCategoryInfo(infoResp.data[0])
        } catch (error) {
            console.log(error)
            toast.error('Nepodařilo se stáhnout data')
        }
    }

    const getCategories = async () => {
        try {
            const catResp = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'categories',
                    type: 'categorySet',
                    specific: categoryId
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setCategories(catResp.data)
        } catch (error) {
            console.log(error)
            toast.error('Nepodařilo se stáhnout data')
        }
    }

    useEffect(() => {
        getCategory()
    }, [])

    const addCategory = async (e) => {
        e.preventDefault()
        const data = JSON.stringify({
            name: categoryName,
            categorySet: categoryId,
        })
        try {
            await axios.post(process.env.REACT_APP_API_URL + '/site', {
                data: data
            },
            {
                params: {
                    database: 'suchomel',
                    table: 'categories'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            toast.success(`Kategorie ${categoryName} přidána`)
            getCategories()
            setCategoryName('')
        } catch (error) {
            console.log(error)
            toast.error('Nepodařilo se přidat kategorii')
        }
    }

    const removeCategory = async () => {
        setModalOpen(false)
        try {
            await axios.delete(process.env.REACT_APP_API_URL + '/site', {
                data: {
                    type: 'id',
                    specific: deleteId
                },
                params: {
                    database: 'suchomel',
                    table: 'categories'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            getCategories()
            toast.success('Kategorie byla úspěšně odstraněna')
        } catch (error) {
            console.log(error)
            toast.error('Nepovedlo se odstranit kategorii')
        }
    }

    const handleDelete = (categoryId) => {
        setDeleteId(categoryId)
        setModalOpen(true)
    }

    const closeHandle = () => {
        setModalOpen(false)
        setDeleteId(null)
    }

    return (
        <div className="wrapper">
            <h2 className="wrapper__heading">
                <Link to='/category'><IconArrowBackUp /></Link>
                Editace kategorie: {categoryInfo?.name}
            </h2>
            <div className="wrapper__list">
                <ul>
                    {!categories &&
                        [...Array(3)].map((e, i) => {
                            return <SkeletonLoader key={i} />
                        })
                    }
                    {categories?.map(category => {
                        return (
                                <li key={category.id} className='wrapper__list-item'>
                                    <p>{category.name}</p>
                                    <button className="btn btn-danger" onClick={() => {handleDelete(category.id)}}>
                                        <IconTrash />
                                    </button>
                                </li>
                            )
                    })}
                </ul>
                <form onSubmit={(e) => {addCategory(e)}} className='form wrapper__form'>
                    <div className="form-group">
                        <p>Název kategorie</p>
                        <input type="text" value={categoryName} onChange={(e) => {setCategoryName(e.target.value)}} />
                    </div>
                    <button className='btn btn-submit'>Přidat</button>
                </form>
            </div>
            <RemoveModal open={modalOpen} closeHandle={closeHandle} confirm={removeCategory} content={'tuto kategorii'} />
        </div>
    )
}

export default CategoryEditor