import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Menu } from './Menu';
import { Home, Texts, TextEditor, ContactEditor, Galleries, GalleryEditor, Categories, CategoryEditor, User, ErrorPage, NetworkError } from '.';
import { PageLoader } from './Loaders';
import { IconCategory, IconHome, IconTextSize, IconPhoto } from '@tabler/icons-react';
import jsCookies from 'js-cookies';
import axios from 'axios';

const Main = () => {
    const [user, setUser] = useState();
    const [error, setError] = useState("");

    const pages = [
        {
            path: '/',
            element: <Home user={user} />,
            menu: true,
            icon: <IconHome className='icon' />,
            text: 'Domů'
        },
        {
            path: '/texts',
            element: <Texts />,
            menu: true,
            icon: <IconTextSize className='icon' />,
            text: 'Texty'
        },
        {
            path: '/texteditor/:textId',
            element: <TextEditor />,
            menu: false
        },
        {
            path: '/contacteditor/:textId',
            element: <ContactEditor />,
            menu: false
        },
        {
            path: '/gallery',
            element: <Galleries />,
            menu: true,
            icon: <IconPhoto className='icon' />,
            text: 'Galerie'
        },
        {
            path: '/gallery/:galleryId',
            element: <GalleryEditor />,
            menu: false
        },
        {
            path: '/category',
            element: <Categories />,
            menu: true,
            icon: <IconCategory className='icon' />,
            text: 'Kategorie'
        },
        {
            path: '/category/:categoryId',
            element: <CategoryEditor />,
            menu: false
        },
        {
            path: '/user',
            element: <User />,
            menu: false
        }
    ]

    const getUser = async () => {
        const token = jsCookies.getItem('userToken')
        try {
            const ip = await axios.get('https://api.ipify.org?format=json')
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site/user', {
                params: {
                    database: 'admin_panel',
                    table: 'tokens',
                    token: token,
                    ipAddress: ip.data.ip
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            if (!response.data.user) {
                jsCookies.removeItem('userToken')
                window.location.href = '/'
            }
            setUser(response.data.user)
        } catch (error) {
            console.log(error);
            if (error.message === 'Network Error') {
                setError('network')
            }
            setTimeout(() => {
                getUser()
            }, 30000)
        }
    }

    useEffect(() => {
        getUser()
    }, [])

    if (error === 'network') {
        return <NetworkError />
    }

    return (
        <>
            {user ?
                <div className='container'>
                    <Menu pages={pages} user={user} />
                    <div className="content">
                        <Routes>
                            {pages.map(page => {
                                return <Route exact path={page.path} element={page.element} key={page.path}/>
                            })}
                            <Route exact path='*' element={<ErrorPage />} />
                        </Routes>
                    </div>
                </div>:
                <PageLoader />
            }
        </>
    )
}

export default Main