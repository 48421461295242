import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import SkeletonLoader from './Loaders/SkeletonLoader'

const Galleries = () => {
    const [gallerySets, setGallerySets] = useState()

    const getGalleries = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/site', {
                params: {
                    database: 'suchomel',
                    table: 'gallerySets'
                },
                headers: {
                    Authorization: process.env.REACT_APP_API_KEY
                }
            })
            setGallerySets(response.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getGalleries()
    }, [])

    return (
        <div className="wrapper">
            <h2 className='wrapper__heading'>Galerie na webu</h2>
            <div className="wrapper__list">
                <ul>
                    {!gallerySets &&
                        [...Array(3)].map((e, i) => {
                            return <SkeletonLoader key={i} />
                        })
                    }
                    {gallerySets?.map(set => {
                        return <li key={set.id}><Link to={'/gallery/' + set.id}>{set.name}</Link></li>
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Galleries