import React, { useEffect } from 'react'
import { useState } from 'react'

const Home = ({ user }) => {
    const [date, setDate] = useState(new Date())
    const [welcome, setWelcome] = useState('')

    const zeroCheck = (num) => {
        return num < 10 ? '0' + num : num
    }
    const time = zeroCheck(new Date(date).getHours()) + ':' + zeroCheck(new Date(date).getMinutes())

    const getWelcome = (hours) => {
        if (hours >= 18 || hours < 6) {
            setWelcome('Dobrý večer')
        } else if (hours > 12 || hours < 18) {
            setWelcome('Dobré odpoledne')
        } else if (hours >= 6 || hours < 9) {
            setWelcome('Dobré ráno')
        } else if (hours >= 9 || hours < 12) {
            setWelcome('Dobré poledne')
        }
    }

    useEffect(() => {
        getWelcome(parseInt(zeroCheck(new Date(date).getHours())))
        setInterval(() => {
            setDate(new Date().valueOf())
            getWelcome(parseInt(zeroCheck(new Date(date).getHours())))
        }, 1000)
    }, [])

    return (
        <>
            <h2 className='home-heading'>Vítej {user.name}</h2>
            <h3 className=''>Administrace stránek Michal Suchomel truhlářství</h3>
            <div className="current-time">
                <p>Momentální čas: {time}</p>
            </div>
        </>
    )
}

export default Home